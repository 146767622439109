import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { AiOutlineArrowLeft, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useSwipeable } from "react-swipeable";
import api from "../Api";
import ReactionSelector from "../components/reactionselector";
import SnippetComment from "../components/snippetcomment";
import { useUser } from '../contexts/UserContext'


function SnippetContent() {
    const { containerId, storiaId, snippetId } = useParams();
    const navigate = useNavigate();
    const [media, setMedia] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [vh, setVh] = useState(window.innerHeight);
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
    const [showModal, setShowModal] = useState(false); // Stato per il modal
    const [showReactionSelector, setShowReactionSelector] = useState(false); // Mostra/nasconde il selettore
    const [reactionPosition, setReactionPosition] = useState({}); // Posizione dinamica del selettore
    const [comments, setComments] = useState([]);
    const [reactions, setReactions] = useState({});
    const [userReaction, setUserReaction] = useState("None");
    const [totaleReazioni, setTotaleReazioni] = useState()
    const [totaleCommenti, setTotaleCommenti] = useState()

    const {user} = useUser()

    useEffect(() => {
        const handleResize = () => {
            setVh(window.innerHeight);
            setIsLargeScreen(window.innerWidth > 768);
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchSnippet = async () => {
            try {
                const response = await api.get(
                    `sparklingApi/contenitori/${containerId}/storie/${storiaId}/snippets/${snippetId}/`
                );
                const snippet = response.data;

                const combinedMedia = [];
                if (snippet.video) {
                    combinedMedia.push({ type: "video", url: snippet.video });
                }
                if (snippet.immagini && snippet.immagini.length > 0) {
                    snippet.immagini.forEach((image) => {
                        combinedMedia.push({ type: "image", url: image });
                    });
                }
                setMedia(combinedMedia);
            } catch (error) {
                console.error("Errore nel recupero dello snippet:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSnippet();
    }, [containerId, storiaId, snippetId]);

    useEffect(() => {
        const fetchCommentsAndReactions = async () => {
            try {
                const response = await api.get(
                    `sparklingApi/contenitori/${containerId}/storie/${storiaId}/snippets/${snippetId}/commentreactionlist`
                );
    
                const { commenti, reazioni, reazioneUtente } = response.data;
    
                // Salva i dati nello stato (aggiungi questi stati nel componente)
                setComments(commenti);
                
                setReactions(reazioni);
                
                setUserReaction(reazioneUtente || "None");
                setTotaleReazioni(response.data.totaleReazioni)
                setTotaleCommenti(response.data.totaleCommenti)
                
            } catch (error) {
                console.error("Errore durante il recupero di commenti e reazioni:", error);
            }
        };
    
        fetchCommentsAndReactions();
    }, [containerId, storiaId, snippetId]);
    

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex < media.length - 1 ? prevIndex + 1 : prevIndex
        );
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrevious,
    });

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <p className="text-gray-500">Caricamento in corso...</p>
            </div>
        );
    }

    const handleReactionClick = () => {
        setShowReactionSelector((prev) => !prev); // Alterna tra true e false
    };
    
    
    const handleSelectReaction = async (reaction) => {
        try {
            // Invia la reazione al backend
            const response = await api.post(
                `sparklingApi/contenitori/${containerId}/storie/${storiaId}/snippets/${snippetId}/reactiontoggle/`,
                { reazione: reaction }
            );
    
            if (response.status === 200 || response.status === 201) {
                const { reazioni, totaleReazioni, totaleCommenti } = response.data;
    
                // Aggiorna lo stato con i nuovi dati
                setReactions(reazioni);
                
                setTotaleReazioni(totaleReazioni);
                setTotaleCommenti(totaleCommenti);
    
                // Aggiorna la reazione dell'utente
                setUserReaction(reaction === userReaction ? "None" : reaction);
                handleReactionClick()
            }
        } catch (error) {
            console.error("Errore durante la gestione della reazione:", error);
            alert("C'è stato un errore. Riprova.");
        }
    };

    const handleAddComment = async (comment) => {
        try {
            const response = await api.post(
                `sparklingApi/contenitori/${containerId}/storie/${storiaId}/snippets/${snippetId}/addcomment/`,
                { testo: comment }
            );
    
            if (response.status === 201) {
                const { commenti, totaleCommenti } = response.data;
    
                // Aggiorna lo stato con i nuovi commenti e il totale
                setComments(commenti);
                setTotaleCommenti(totaleCommenti);
            }
        } catch (error) {
            console.error("Errore durante l'aggiunta del commento:", error);
            alert("C'è stato un errore. Riprova.");
        }
    };
    
    const handleDeleteComment = async (commentId) => {
        try {
            const response = await api.delete(
                `sparklingApi/contenitori/${containerId}/storie/${storiaId}/snippets/${snippetId}/deletecomment/${commentId}/`
            );
    
            if (response.status === 200) {
                const { commenti, totaleCommenti } = response.data;
    
                // Aggiorna lo stato con i nuovi commenti e il totale
                setComments(commenti);
                setTotaleCommenti(totaleCommenti);
            }
        } catch (error) {
            console.error("Errore durante l'eliminazione del commento:", error);
            alert("C'è stato un errore. Riprova.");
        }
    };
    
    
    
    

    return (
        <div
            className="flex flex-col items-center bg-black relative overflow-hidden"
            {...swipeHandlers}
            style={{ height: vh }}
        >
            <button
                onClick={() => navigate(-1)}
                className="absolute top-4 left-4 bg-gray-800 text-white rounded-full p-2 focus:outline-none z-50"
            >
                <AiOutlineArrowLeft size={24} />
            </button>

            {media.length > 0 && (
                <div className="relative w-full h-full ">
                    {media[currentIndex].type === "video" ? (
                        <ReactPlayer
                            url={media[currentIndex].url}
                            playing
                            controls
                            width="100%"
                            height="100%"
                            className="object-contain"
                        />
                    ) : (
                        <img
                            className="w-full h-full object-contain"
                            src={media[currentIndex].url}
                            alt={`Snippet media ${currentIndex + 1}`}
                        />
                    )}

                    {isLargeScreen && (
                        <>
                            <button
                                onClick={handlePrevious}
                                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full shadow-md focus:outline-none z-50"
                                style={{ display: currentIndex > 0 ? "block" : "none" }}
                            >
                                <AiOutlineLeft size={24} />
                            </button>
                            <button
                                onClick={handleNext}
                                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full shadow-md focus:outline-none z-50"
                                style={{
                                    display: currentIndex < media.length - 1 ? "block" : "none",
                                }}
                            >
                                <AiOutlineRight size={24} />
                            </button>
                        </>
                    )}

                    <div className="absolute bottom-4 w-full flex flex-col items-center space-y-2 pointer-events-none">
                        <div className="flex justify-center items-center space-x-2">
                            {media.map((_, index) => (
                                <div
                                    key={index}
                                    className={`w-2 h-2 rounded-full ${
                                        index === currentIndex ? "bg-white" : "bg-gray-400"
                                    }`}
                                ></div>
                            ))}
                        </div>
                        
                        {showReactionSelector && (
                            <ReactionSelector
                                onSelect={handleSelectReaction} // Passa la funzione di selezione
                                reazioneUtente={userReaction}
                                
                            />
                        )}

                        <div className="bg-black bg-opacity-50 text-white w-11/12 md:w-1/2 p-2 rounded-lg flex justify-around items-center pointer-events-auto">
                            {/* Sezione Reazioni */}
                            <span
                                className="cursor-pointer"
                                onClick={handleReactionClick} // Apri il selettore quando cliccato
                            >
                                ❤️😂😮😢😡: <b>{totaleReazioni}</b>
                            </span>

                            {/* Sezione Commenti */}
                            <span
                                className="cursor-pointer"
                                onClick={() => setShowModal(true)} // Apri il modale commenti quando cliccato
                            >
                                Commenti: <b>{totaleCommenti}</b>
                            </span>
                        </div>

                    </div>
                </div>
            )}

            {showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
                <div className="bg-white w-11/12 md:w-1/2 p-6 rounded-lg shadow-lg relative h-[80vh] flex flex-col">
                <h2 className="text-xl font-bold mb-4">Commenti e Reazioni</h2>
                <button
                    onClick={() => setShowModal(false)}
                    className="absolute top-4 right-4 bg-gray-200 text-gray-800 rounded-full p-2 focus:outline-none"
                >
                    ✕
                </button>
                <SnippetComment
                    comments={comments}
                    reactions={reactions}
                    user={user} // Adatta in base al metodo di autenticazione
                    handleAddComment={handleAddComment}
                    handleDeleteComment={handleDeleteComment}
                />
                </div>
            </div>
            )}
        </div>
    );
}

export default SnippetContent;
