import React, { useState, useEffect } from 'react';
import Chat from '../components/chat';
import { useParams } from 'react-router-dom';
import api from '../Api';
import { loadStripe } from '@stripe/stripe-js';
import Navbar from '../components/navbar';
import NavbarMobile from '../components/navbarmobile';
import { useUser } from '../contexts/UserContext';

// Inizializza Stripe con la tua chiave pubblica
const stripePromise = loadStripe('pk_test_51QNM3sP2eiNnuT9auX2IVHiDSwoQVPvIdnp4ZWWkPFtdqmLhypCmgSAgDAayWXNyMblzpd9FNMVNzwaV9asNBED4008Jnvb5mB');

function ChatConversation() {
  const params = useParams();
  const { user } = useUser(); // Ottieni l'utente loggato
  const [hasValidPurchase, setHasValidPurchase] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPaymentScreen, setShowPaymentScreen] = useState(false);
  const [isPremium, setIsPremium] = useState(true);

  // Funzione per verificare se esiste una transazione valida
  const checkValidPurchase = async () => {
    try {
      // Se l'utente loggato è lo stesso creator, consenti l'accesso senza pagamento
      if (params.creator === user.username) {
        setHasValidPurchase(true);
        setLoading(false);
        return;
      }

      const response = await api.get(`/sparklingApi/checkpurchase/${params.creator}/${params.conversationId}`);
      
      if (response.data.is_premium === true && response.data.valid_purchase === false) {
        setHasValidPurchase(response.data.valid_purchase);
        setShowPaymentScreen(true);
        setIsPremium(response.data.is_premium)
      } else {
        setShowPaymentScreen(false);
        setIsPremium(response.data.is_premium);
        setHasValidPurchase(response.data.valid_purchase);
      }
      
    } catch (error) {
      console.error('Errore durante il controllo della transazione valida:', error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    checkValidPurchase(); // Controlla la transazione al caricamento della pagina
    
  }, [params.creator]);

  // Funzione per gestire il pagamento
  const handlePayment = async () => {
    try {
      const stripe = await stripePromise;
  
      // Richiedi al backend di creare una sessione di pagamento
      const response = await api.post('/sparklingApi/createcheckoutsession/', {
        creator: params.creator,
        receiver: params.receiver, // Passa il destinatario
      });
  
      const session = response.data;
  
      if (session.sessionId) {
        // Reindirizza l'utente alla schermata di pagamento di Stripe
        const result = await stripe.redirectToCheckout({ sessionId: session.sessionId });
  
        if (result.error) {
          console.error('Errore durante il reindirizzamento a Stripe:', result.error.message);
        }
      } else {
        console.error('Errore nella creazione della sessione di pagamento');
      }
    } catch (error) {
      console.error('Errore durante il pagamento:', error);
    }
  };
  

  if (loading) {
    return <div>Caricamento...</div>;
  }

  if (showPaymentScreen) {
    return (
      <>
        <div className="min-h-screen ">
          <Navbar />

          <div className="grid grid-cols-12 text-center mx-6 mt-24">
            <div className="md:col-span-3 sm:col-span-1 sm:block hidden"></div>
            <div className="md:col-span-6 sm:col-span-10 col-span-12 rounded-lg shadow-2xl p-6 ">
              <div className="flex justify-between items-center ">
                <p className="font-poppinsBold text-primary text-sm">One-time payment</p>
                <p className="rounded-full bg-slate-100 font-poppinsBold p-2 text-xs text-primary">
                  Accesso immediato
                </p>
              </div>

              <div className="text-left my-6 text-slate-700 font-poppinsLight">
                Accedi subito a tutti i contenuti che <b>{params.creator}</b> ha condiviso con te.
              </div>

              <div className="text-left mt-8 flex items-baseline">
                <p className=" text-black font-poppinsBold text-5xl">€6.99</p>
                <p className="text-slate-700 font-poppinsLight text-sm pl-1">I.V.A. Compresa</p>
              </div>

              <button
                onClick={handlePayment}
                className="mt-8 w-full text-md bg-primary text-white font-poppinsBold  py-2 rounded-3xl hover:bg-transparent hover:border-2 hover:border-primary hover:text-primary hover:font-bold sm:w-4/5"
              >
                Acquista ora >
              </button>

              <div className="mt-6">
                <ul className="text-left ">
                  <li className="flex items-center mt-2">
                    <b className="text-primary mr-4 text-lg">✓</b>
                    <p className="font-poppinsLight text-slate-700 text-base">
                      Pagamento unico, nessun abbonamento mensile.
                    </p>
                  </li>

                  <li className="flex items-center mt-2">
                    <b className="text-primary mr-4 text-lg">✓</b>
                    <p className="font-poppinsLight text-slate-700 text-base">
                      Accesso di 24 ore a tutti i contenuti di {params.creator}
                    </p>
                  </li>

                  <li className="flex items-center mt-2">
                    <b className="text-primary mr-4 text-lg">✓</b>
                    <p className="font-poppinsLight text-slate-700 text-base">
                      Fatti notare con i commenti e le reazioni ai messaggi ❤️😂😮😢😡
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:col-span-3 sm:col-span-1 sm:block hidden"></div>
          </div>

          <NavbarMobile />
        </div>
      </>
    );
  }

  if (hasValidPurchase || isPremium === false) {
    return <Chat username={params.creator} receiver={params.receiver} is_premium = {isPremium} />;
  }

  return null;
}

export default ChatConversation;