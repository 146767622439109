import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { FiTrash2 } from "react-icons/fi"; // Icona del cestino
import api from "../Api";
import Spinner from "./spinner"
import StorieCards from "./storiecards";

function ViewSnippets() {
  const [showForm, setShowForm] = useState(false); // Stato per mostrare/nascondere la form per creare un contenitore
  const [name, setName] = useState(""); // Stato per il nome del contenitore
  const [description, setDescription] = useState(""); // Stato per la descrizione del contenitore
  const [contenitori, setContenitori] = useState([]); // Stato per la lista dei contenitori
  const [showModal, setShowModal] = useState(false); // Stato per mostrare/nascondere il modale di conferma eliminazione
  const [contenitoreToDelete, setContenitoreToDelete] = useState(null); // Contenitore selezionato per l'eliminazione
  const [modalForSnippet, setModalForSnippet] = useState(false); // Stato per mostrare il modale di aggiunta snippet
  const [currentContainer, setCurrentContainer] = useState(null); // Contenitore attualmente selezionato per aggiungere snippet
  const [snippetTitle, setSnippetTitle] = useState(""); // Stato per il titolo dello snippet
  const [snippetDescription, setSnippetDescription] = useState(""); // Stato per la descrizione dello snippet
  const [photos, setPhotos] = useState([]); // Stato per le foto caricate
  const [video, setVideo] = useState(null); // Stato per il video caricato
  const [selectedImage, setSelectedImage] = useState(null); // Per l'immagine ingrandita
  const videoInputRef = React.useRef(null);
  const photoInputRef = React.useRef(null);
  const [loading, setLoading] = useState(false); // Stato per indicare il caricamento






  const { user } = useUser(); // Ottieni l'utente loggato dal context
  const { username } = useParams(); // Ottieni il parametro username dall'URL

  // Recupera la lista dei contenitori all'inizio
  useEffect(() => {
    const fetchContenitori = async () => {
      try {
        const response = await api.get("sparklingApi/contenitori/");
        setContenitori(response.data);
      } catch (error) {
        console.error("Errore nel recupero dei contenitori:", error);
      }
    };

    fetchContenitori();
  }, []); // Effettua la richiesta solo al montaggio del componente

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("sparklingApi/contenitori/", {
        nome: name,
        descrizione: description,
      });
      setShowForm(false); // Chiude la form dopo il submit
      setName(""); // Resetta il campo del nome
      setDescription(""); // Resetta il campo della descrizione
      setContenitori((prev) => [...prev, response.data]); // Aggiunge il nuovo contenitore alla lista
    } catch (error) {
      console.error("Errore durante la creazione del contenitore:", error);
    }
  };

  const handleDeleteConfirmation = (id) => {
    setContenitoreToDelete(id);
    setShowModal(true); // Mostra il modale di conferma
  };

  const handleDelete = async () => {
    try {
      await api.delete(`sparklingApi/contenitori/${contenitoreToDelete}/`);
      setContenitori((prev) =>
        prev.filter((contenitore) => contenitore.id !== contenitoreToDelete)
      );
      setShowModal(false); // Chiudi il modale di conferma
      setContenitoreToDelete(null); // Resetta il contenitore da eliminare
    } catch (error) {
      console.error("Errore durante l'eliminazione del contenitore:", error);
    }
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);

    // Adatta l'altezza dinamicamente
    e.target.style.height = "auto"; // Resetta l'altezza
    e.target.style.height = `${e.target.scrollHeight}px`; // Imposta l'altezza in base al contenuto
  };

  const handleOpenSnippetModal = (contenitore) => {
    setCurrentContainer(contenitore);
    setModalForSnippet(true); // Mostra il modale per aggiungere snippet
  };

  const handleSnippetSubmit = async (e) => {
    e.preventDefault();

    if (!snippetTitle || photos.length === 0) {
        alert("Il titolo e almeno una foto sono obbligatori.");
        return;
    }

    setLoading(true); // Mostra il loader
    const currentDatetime = new Date()
      .toISOString()
      .replace(/[-:T]/g, "_")
      .replace("Z", "")
      .replace(".", "_");

    try {
        const uploadedImages = [];

        for (let i = 0; i < photos.length; i++) {
            const photo = photos[i];
            const fileType = photo.type;

            // Costruzione del nuovo percorso per il file_name
            const presignedResponse = await api.post("sparklingApi/presigned-url/", {
                file_name: `${user.username}/Storie/${currentContainer.nome}/${snippetTitle}/Snippet/${currentDatetime}/${photo.name}`,
                file_type: fileType,
            });

            const presignedUrl = presignedResponse.data.url;

            const uploadResponse = await fetch(presignedUrl, {
                method: "PUT",
                body: photo,
                headers: { "Content-Type": fileType },
            });

            if (!uploadResponse.ok) {
                console.error("Errore durante l'upload:", uploadResponse.statusText);
                alert("Errore durante l'upload dell'immagine.");
                continue;
            }

            uploadedImages.push(presignedUrl.split("?")[0]);
        }

        let videoUrl = null;
        if (video) {
            const videoType = video.type;

            const presignedResponse = await api.post("sparklingApi/presigned-url/", {
              file_name: `${user.username}/Storie/${currentContainer.nome}/${snippetTitle}/Snippet/${currentDatetime}/${video.name}`,
                file_type: videoType,
            });

            const presignedUrl = presignedResponse.data.url;

            const uploadResponse = await fetch(presignedUrl, {
                method: "PUT",
                body: video,
                headers: { "Content-Type": videoType },
            });

            if (!uploadResponse.ok) {
                console.error("Errore durante l'upload del video:", uploadResponse.statusText);
                alert("Errore durante l'upload del video.");
            } else {
                videoUrl = presignedUrl.split("?")[0];
            }
        }

        // Crea la storia e salva i media
        await api.post(`sparklingApi/contenitori/${currentContainer.id}/crea-storia/`, {
            titolo: snippetTitle,
            immagini: uploadedImages,
            video: videoUrl,
        });

        alert("Storia creata con successo!");
        setSnippetTitle("");
        setPhotos([]);
        setVideo(null);
        setModalForSnippet(false);
    } catch (error) {
        console.error("Errore durante la creazione dello snippet:", error);
        alert("Errore durante la creazione dello snippet.");
    } finally {
        setLoading(false); // Nascondi il loader
    }
};






  const handlePhotoUpload = (e) => {
    const selectedFiles = Array.from(e.target.files).slice(0, 100); // Max 100 foto
    setPhotos(selectedFiles);
  };

  const handleVideoUpload = (e) => {
    setVideo(e.target.files[0]);
  };

  // Apre il modale per l'immagine ingrandita
  const handleImageClick = (photo) => {
    setSelectedImage(photo);
  };

  // Rimuove una foto selezionata
  const handleRemovePhoto = (index) => {
    const updatedPhotos = photos.filter((_, i) => i !== index);
    setPhotos(updatedPhotos);

    if (photoInputRef.current) {
        if (updatedPhotos.length === 0) {
            // Se non ci sono più foto, resetta l'input file
            photoInputRef.current.value = "";
        } else {
            // Altrimenti, aggiorna l'elenco dei file nell'input file
            const dataTransfer = new DataTransfer();
            updatedPhotos.forEach((file) => dataTransfer.items.add(file));
            photoInputRef.current.files = dataTransfer.files;
        }
    }
};

  

  const handleRemoveVideo = () => {
    setVideo(null); // Rimuove il video dallo stato
    if (videoInputRef.current) {
      videoInputRef.current.value = ""; // Resetta il campo file
    }
  };

  const handleCancelSnippet = () => {
    setSnippetTitle(""); // Resetta il titolo
    setPhotos([]); // Resetta le foto caricate
    setVideo(null); // Resetta il video caricato

    // Resetta il valore degli input file
    if (photoInputRef.current) {
        photoInputRef.current.value = "";
    }
    if (videoInputRef.current) {
        videoInputRef.current.value = "";
    }

    setModalForSnippet(false); // Chiudi il modale
};


  


  


  return (
    <div className="flex flex-col items-center">
      
      {user.username === username && (
        <>
        <p className="text-slate-700 text-lg font-poppinsBold mt-6">
        Crea un contenitore per una persona specifica
      </p>

      
      <button
        className={`mt-6 w-12 h-12 rounded-full ${
          showForm ? "bg-gray-300 text-gray-700" : "bg-primary text-white"
        } text-2xl flex items-center justify-center shadow-md transition-all`}
        onClick={() => setShowForm(!showForm)} // Alterna tra "+" e "-"
      >
        {showForm ? "-" : "+"}
      </button>
      </>
      )}

      {/* Form per creare un nuovo contenitore */}
      {showForm && (
        <div className="mt-6 w-full md:w-3/4 bg-white p-6 rounded-lg shadow-lg">
          <form onSubmit={handleFormSubmit}>
            {/* Campo Nome */}
            <div className="mb-4 flex flex-col items-center">
              <label htmlFor="name" className="text-slate-700 font-medium mb-2">
                Nome
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="md:w-3/4 w-full max-w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                placeholder="Con chi avrai queste storie?"
                required
              />
            </div>

            {/* Campo Descrizione */}
            <div className="mb-4 flex flex-col items-center">
              <label
                htmlFor="description"
                className="text-gray-700 font-medium mb-2"
              >
                Descrizione:
              </label>
              <textarea
                id="description"
                value={description}
                onChange={handleDescriptionChange}
                className="md:w-3/4 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent resize-none"
                placeholder="Chi è per te questa persona?"
                rows={3} // Altezza iniziale
              />
            </div>

            {/* Bottoni */}
            <div className="flex flex-col items-center">
              <button
                type="submit"
                className="bg-primary md:w-1/2 w-full mt-4 text-white font-poppinsLight text-lg px-4 py-2 rounded-lg hover:bg-primary-dark transition-all"
              >
                Crea contenitore
              </button>
              <button
                type="button"
                className="bg-gray-200 md:w-1/2 w-full text-gray-700 font-poppinsLight text-lg mt-2 px-4 py-2 rounded-lg hover:bg-gray-300 transition-all"
                onClick={() => setShowForm(false)} // Nascondi la form
              >
                Annulla
              </button>
            </div>
          </form>
        </div>
      )}

      

      {/* Elenco dei contenitori */}
      <ul className="mt-6 w-full md:w-3/4 ">
      
        {contenitori.length > 0 ? (
          
          contenitori.map((contenitore) => (
            <li
              key={contenitore.id}
              className="mb-4 px-4 pt-4 pb-2 border border-gray-300 rounded-lg shadow-sm"
            >
              <div className="flex justify-between items-center">
                <h3 className="text-primary text-start font-poppinsLight">
                  Storie con: <b>{contenitore.nome}</b>
                </h3>

                {user.username === username && (
                  <button
                    className="w-8 h-8 bg-gray-200 text-gray-700 rounded-full flex items-center justify-center hover:bg-gray-300"
                    onClick={() => handleDeleteConfirmation(contenitore.id)}
                  >
                    <FiTrash2 />
                  </button>
                )}
              </div>
              <p className="text-gray-700 text-start font-poppinsLight text-sm">
                {contenitore.descrizione}
              </p>
              {user.username === username && (
                <>
              <p className="text-slate-700 md:text-base text-sm font-poppinsLight mt-6">
                Crea le storie che vuoi raccontare: 
              </p>

              <div className="mt-4 flex justify-center">
                <button
                  className="w-12 h-12 bg-primary text-white rounded-full flex items-center justify-center hover:bg-primary-dark"
                  onClick={() => handleOpenSnippetModal(contenitore)}
                >
                  +
                </button>
              </div>
              <p className="text-slate-700 md:text-base text-sm font-poppinsLight mt-4">
                ed aggiungi Snippet alle storie per tenere i tuoi fan aggiornati: 
              </p>
              </>)}

              {/* Component per le storie */}
              <div className="mt-4">
                <StorieCards containerId={contenitore.id} />
              </div>
            </li>
          ))
        ) : (
          <p className="text-gray-500 text-center">Ancora nessuna storia...</p>
        )}
        
      </ul>
      
      


      {/* Modale di conferma eliminazione */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white w-full md:w-1/2 p-6 rounded-lg shadow-lg">
            <p className="text-lg text-center text-gray-700">
              Sei sicuro di voler eliminare questo contenitore?
            </p>
            <div className="mt-4 flex justify-center space-x-4">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
                onClick={handleDelete}
              >
                Elimina
              </button>
              <button
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
                onClick={() => setShowModal(false)}
              >
                Annulla
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modale di aggiunta snippet */}
      {modalForSnippet && (
  <div
    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    onScroll={(e) => e.stopPropagation()} // Previene lo scroll del background
  >
    <div
      className="bg-white w-11/12 md:w-3/4 lg:w-1/2 p-6 rounded-lg shadow-lg max-h-[80vh] overflow-y-auto"
    >
      <h2 className="text-2xl font-poppinsLight text-center text-primary mb-4">
        Aggiungi una Storia con <b>{currentContainer.nome}</b>
      </h2>
      <form onSubmit={handleSnippetSubmit}>
        <div className="mb-4 font-poppinsLight text-lg flex flex-col items-center">
          <label htmlFor="snippetTitle" className="block text-gray-700 mb-2">
            Titolo
          </label>
          <input
            type="text"
            id="snippetTitle"
            value={snippetTitle}
            onChange={(e) => setSnippetTitle(e.target.value)}
            className="block md:w-3/4 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent resize-none font-poppinsLight text-slate-700 text-lg"
            placeholder="Ex. Organizzazione weekend! 🙃😂"
            required
          />
        </div>
        {/* Restante contenuto del modale */}
        <div className="mb-4 flex flex-col items-center">
          <label className="block text-gray-700 mb-2 font-poppinsLight text-lg">
            Carica gli screenshot
          </label>
          <input
            type="file"
            accept="image/*"
            multiple
            required
            onChange={handlePhotoUpload}
            ref={photoInputRef} // Aggiungi il ref
            className="block md:w-3/4 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent resize-none font-poppinsLight text-slate-700 text-lg"
          />

          <div className="flex mt-2 space-x-2">
            {photos.map((photo, index) => (
              <div key={index} className="relative w-16 h-16">
                <img
                  src={URL.createObjectURL(photo)}
                  alt="preview"
                  className="object-cover w-full h-full rounded-md cursor-pointer"
                  onClick={() => handleImageClick(photo)}
                />
                <button
                  type="button"
                  onClick={() => handleRemovePhoto(index)}
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs"
                >
                  ×
                </button>
              </div>
            ))}
          </div>
          {selectedImage && (
            <div
              className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
              onClick={() => setSelectedImage(null)}
            >
              <img
                src={URL.createObjectURL(selectedImage)}
                alt="Preview"
                className="max-w-full max-h-full rounded-lg"
              />
            </div>
          )}
        </div>
        {/* Altre sezioni come il caricamento del video */}
        <div className="mb-4 flex flex-col items-center">
          <label className="block text-gray-700 mb-2 font-poppinsLight text-lg">
            Carica un video
          </label>
          <p className="font-poppinsLight text-slate-700 text-sm mb-2">
            Puoi caricare un video preview per spiegare il contenuto ai tuoi fan:
          </p>
          <input
            type="file"
            accept="video/*"
            onChange={handleVideoUpload}
            ref={videoInputRef} // Aggiungi il ref
            className="block md:w-3/4 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent resize-none font-poppinsLight text-slate-700 text-lg"
          />

          {video && (
            <div className="mt-2 w-full flex flex-col items-center">
              <video
                src={URL.createObjectURL(video)}
                className="w-full h-40 rounded-md"
                controls
              />
              <button
                type="button"
                onClick={handleRemoveVideo}
                className="mt-2 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 text-sm"
              >
                Rimuovi video
              </button>
            </div>
          )}

        </div>
        <br />
        {loading && (
              <div className="flex flex-col justify-center items-center mb-4">
                <p className="text-primary font-poppinsLight mb-2">Potrebbe volerci qualche minuto...</p>
                <Spinner />
              </div>
          )}

        <div className="flex justify-end space-x-4">
        <button
              type="submit"
              className={`bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-dark font-poppinsLight text-lg ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading}
          >
              Salva Storia
          </button>
          <button
              type="button"
              className={`bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300 font-poppinsLight text-lg ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={handleCancelSnippet}
              disabled={loading}
          >
              Annulla
          </button>


        </div>
      </form>
    </div>
    
  </div>
  
)}
    <br/>
    <br/>
    
    </div>
  );
}

export default ViewSnippets;
