import React, { useState, useEffect } from "react";
import { useParams  } from "react-router-dom";
import api from "../Api";

const reactions = [
  { key: "cuore", label: "❤️" },
  { key: "risata", label: "😂" },
  { key: "stupore", label: "😮" },
  { key: "triste", label: "😢" },
  { key: "rabbia", label: "😡" },
];




const ReactionSelector = ({ onSelect, reazioneUtente }) => {
  return (
<div className="absolute bottom-12 w-1/2 left-1/2 transform -translate-x-1/2 bg-white shadow-md rounded-lg p-1 z-[1000] pointer-events-auto">
    <div className="flex justify-around items-center">
        {reactions.map((reaction) => (
            
          <button
            key={reaction.key}
            className={`text-xl hover:scale-125 transition-transform ${
              reazioneUtente === reaction.key
                ? "bg-gray-200 rounded-full p-2"
                : ""
            }`}
            onClick={(e) => {
                e.stopPropagation(); // Blocca la propagazione degli eventi
                onSelect(reaction.key);
                
              }}
          >
            {reaction.label}
            
          </button>
          
        ))}
      </div>
    </div>
  );
};

export default ReactionSelector;
