import React, { useState, useEffect, useRef } from 'react';
import api from '../Api';
import { useUser } from '../contexts/UserContext';
import background from '../Img/chatbackground.png';
import logo from "../Img/1.png";
import { FiArrowLeft } from 'react-icons/fi';
import { useFetcher, useNavigate } from 'react-router-dom';
import MessageActions from '../components/messageactions';
import { useParams } from 'react-router-dom';



function Chat({ username, receiver, is_premium }) {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useUser();
  const navigate = useNavigate();
  const chatContainerRef = useRef(null);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const params = useParams();
  

  // Funzione per verificare la validità del pagamento
  const checkPaymentValidity = async () => {
    try {
      const response = await api.get(`/sparklingApi/checkpurchase/${username}/`);
      if (!response.data.valid_purchase) {
        // Se il pagamento è scaduto, ricarica la pagina
        window.location.reload();
      }
    } catch (error) {
      console.error('Errore durante il controllo della validità del pagamento:', error);
    }
  };

  const fetchConversation = async (shouldScroll = true) => {
    try {
      const response = await api.get(`/sparklingApi/conversations/${username}/${receiver}/`);
      setMessages(response.data.messages);
      setLoading(false);
      setShouldScrollToBottom(shouldScroll);
    } catch (error) {
      console.error('Errore nel caricamento della conversazione:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConversation();
    const interval = setInterval(() => {
      // Controlla validità pagamento solo se chi apre non è il creator
      if (params.creator !== user.username && is_premium === true) {
        checkPaymentValidity();
      }
    }, 60000); // Ogni 60 secondi
  
    return () => clearInterval(interval); // Pulizia al dismount del componente
  }, [username, receiver, params.creator, user.username])

  

  useEffect(() => {
    if (shouldScrollToBottom && chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages, shouldScrollToBottom]);

  useEffect(() => {
    if (window.gtag) {
      window.gtag("event", "loaded_chat", {
        event_category: "User Interaction",
        event_label: "My Custom Load",
        value: 1, // Valore numerico facoltativo
      });
    }
  },[])

  const handleAddReaction = async (messageId, reaction) => {
    try {
      await api.post(`/sparklingApi/messages/${messageId}/reactions/`, {
        message: messageId,
        user: user.id,
        tipo: reaction,
      });
      fetchConversation(false);
    } catch (error) {
      console.error("Errore durante l'aggiunta della reazione:", error);
    }
  };

  const handleAddComment = async (messageId, comment) => {
    try {
      await api.post(`/sparklingApi/messages/${messageId}/comments/`, {
        message: messageId,
        user: user.id,
        testo: comment,
      });
      fetchConversation(false);
    } catch (error) {
      console.error("Errore durante l'aggiunta del commento:", error);
    }
  };

  const handleDeleteComment = async (messageId, commentId) => {
    try {
      await api.delete(`/sparklingApi/messages/${messageId}/comments/${commentId}/`);
      fetchConversation(false);
    } catch (error) {
      console.error("Errore durante l'eliminazione del commento:", error);
    }
  };

  const handleReportMessage = async (messageId, mediaUrl) => {
    try {
      const payload = { user: user.id };
      if (mediaUrl) {
        payload.media_url = mediaUrl; // Aggiungi l'URL del media se presente
      }
      const response = await api.post(`/sparklingApi/messages/${messageId}/report/`, payload);
      alert(response.data.message || "Segnalazione effettuata con successo.");
    } catch (error) {
      console.error("Errore durante la segnalazione del messaggio:", error);
      alert("Errore durante la segnalazione. Riprova più tardi.");
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('it-IT', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  };

  const isNewDay = (currentMessage, previousMessage) => {
    if (!previousMessage) return true;
    const currentDate = new Date(currentMessage.timestamp);
    const previousDate = new Date(previousMessage.timestamp);
    return currentDate.toDateString() !== previousDate.toDateString();
  };

  if (loading) return <div>Caricamento...</div>;

  return (
    <div className="min-h-screen flex flex-col items-center relative">
      <div className="w-full max-w-md shadow-md rounded-lg overflow-hidden relative" style={{ backgroundImage: `url(${background})`, height: '100vh' }}>
        <div className="bg-white bg-opacity-80 border-b border-slate-200 text-black p-4 flex items-center fixed top-0 w-full max-w-md z-10">
          <button onClick={() => navigate(`/profile/${username}`)} className="flex items-center mr-2">
            <FiArrowLeft size={24} className="text-slate-500" />
          </button>
          <div className="flex-grow text-center">
            <p className="text-sm font-poppinsLight">Conversazione tra<br /><b>{username}</b> e <b>{receiver}</b></p>
          </div>
        </div>

        <div ref={chatContainerRef} className="pt-[60px] pb-[70px] p-4 space-y-4 overflow-y-scroll" style={{ height: `100vh`, paddingBottom: '100px' }}>
          {messages.map((message, index) => (
            <React.Fragment key={message.id || index}>
              {isNewDay(message, messages[index - 1]) && (
                <div className="text-center my-4">
                  <span className="px-4 py-1 rounded-full bg-gray-300 text-sm text-gray-700">
                    {formatDate(message.timestamp)}
                  </span>
                </div>
              )}
              <div className={`select-none flex ${message.sender === username ? 'justify-end' : 'justify-start'}`}>
                <div className={`p-2 rounded-lg ${message.sender === username ? 'bg-[#1ce75a] text-grey-800' : 'bg-gray-200 text-gray-800'}`}>
                  <p className="font-semibold">{message.sender}</p>
                  {message.text ? (
                    <p>{message.text}</p>
                  ) : (
                    message.file_name && message.file_url && (
                      <>
                      {message.file_name && message.file_url && (
                        <>
                          {message.file_name.match(/\.(jpg|jpeg|png)$/i) ? (
                            <img src={message.file_url} alt={message.file_name} className="max-w-xs rounded-lg my-2" />
                          ) : message.file_name.match(/\.(mp4)$/i) ? (
                            <video controls src={message.file_url} className="max-w-xs rounded-lg my-2" />
                          ) : message.file_name.match(/\.(mp3|wav|opus)$/i) ? (
                            <audio controls src={message.file_url} className="my-2" />
                          ) : null}
                        </>
                      )}
                      </>

                      
                    )
                  )}
                  <p className="text-xs text-gray-500 mt-1 text-right">{new Date(message.timestamp).toLocaleTimeString()}</p>
                  <MessageActions
                    messageId={message.id}
                    reactions={message.reazioni || []}
                    comments={message.commenti || []}
                    userId={user.id}
                    onAddReaction={handleAddReaction}
                    onAddComment={handleAddComment}
                    onDeleteComment={handleDeleteComment}
                    onReportMessage={() => handleReportMessage(message.id, message.file_url || null)}
                  />
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>

        <div className="bg-white bg-opacity-80 border-t border-slate-200 fixed bottom-0 w-full max-w-md flex flex-col items-center py-2 z-10">
          <img src={logo} className="h-[40px]" alt="Logo Sparkling" />
          <p className="font-poppinsLight text-sm">www.sparklingapp.it</p>
        </div>
      </div>
    </div>
  );
}

export default Chat;
