import { useState } from "react";
import React from 'react'
import api from "../Api";
import { useUser } from "../contexts/UserContext";
import { AiOutlineInfoCircle } from "react-icons/ai";



function UploadConversation() {

    const [receiverName, setReceiverName] = useState('');
    const [zipFile, setZipFile] = useState(null);
    const [receiverDesc, setReceiverDesc] = useState('')
    const [uploadMessage, setUploadMessage] = useState('');
    const { user } = useUser()

    const [uploadSelected, setUploadSelected] = useState("selected")
    const [chatSelected, setChatSelected] = useState()
    const [venditeSelected, setVenditeSelected] = useState()
    const [isPremium, setIsPremium] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false);

    

    const [startDate, setStartDate] = useState(
      new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split("T")[0]
    );
    

    // Gestisce il caricamento del file
    const handleFileChange = (e) => {
      setZipFile(e.target.files[0]);
    };
  
    // Gestisce l'invio del form
    // Gestisce l'invio del form con il flusso completo
const handleSubmit = async (e) => {
  e.preventDefault();
  setUploadMessage("Caricamento in corso...");

  if (!receiverName || !zipFile || !receiverDesc || !startDate) {
    setUploadMessage("Inserisci tutti i dati richiesti.");
    return;
  }

  try {
    // Step 1: Genera il presigned URL
    const presignedUrlResponse = await api.post("/sparklingApi/upload_conversation_presigned/", {
      receiver_name: receiverName,
      file_name: zipFile.name,
    });

    const { presigned_url, s3_key } = presignedUrlResponse.data;
    setUploadMessage("Grazie! Stiamo gestendo l'upload del tuo file, nel mentre puoi lasciare la schermata. Controlla la sezione Chat tra una decina di minuti!");
    // Step 2: Carica il file su S3
    const s3UploadResponse = await fetch(presigned_url, {
      method: "PUT",
      body: zipFile,
      headers: {
        "Content-Type": "application/zip",
      },
    });

    if (!s3UploadResponse.ok) {
      setUploadMessage("Errore durante il caricamento su S3.");
      return;
    }

    // Step 3: Invia i dati della conversazione al backend
    const createConversationResponse = await api.post("/sparklingApi/upload_conversation/", {
      s3_key: s3_key,
      receiver_name: receiverName,
      receiver_desc: receiverDesc,
      start_date: startDate,
      is_premium: isPremium,
    });

    if (createConversationResponse.status === 202) {
      setUploadMessage("Conversazione in elaborazione. Verrai notificato quando sarà pronta.");
      setReceiverName("");
      setZipFile(null);
      setReceiverDesc("");
      setStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split("T")[0]);
      setIsPremium(false);
    } else {
      setUploadMessage("Errore durante la creazione della conversazione.");
    }
  } catch (error) {
    console.error("Errore:", error);
    setUploadMessage("Errore durante il processo di caricamento.");
  }
};

    
    
  return (
    <>
    <div className="min-h-fit bg-slate-50 p-6 flex flex-col items-center mb-8">
        <div className=" p-6 m-3 shadow-md rounded-lg bg-white w-full max-w-lg">
          
  
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="receiverName" className="block text-gray-700 font-poppinsBold mb-1">
                Nome del destinatario
              </label>
              <input
                type="text"
                id="receiverName"
                name="receiverName"
                value={receiverName}
                onChange={(e) => setReceiverName(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-primary"
                placeholder="Es. Andrea "
                required
              />
            </div>
            <div>
              <label htmlFor="startDate" className="block text-gray-700 font-poppinsBold mb-1">
                Data Inizio Chat
              </label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-primary"
                required
              />
            </div>
            <div>
              <label htmlFor="receiverName" className="block text-gray-700 font-poppinsBold mb-1">
                Descrizione
              </label>
              
              <input
                type="text"
                id="receiverDesc"
                name="receiverDesc"
                value={receiverDesc}
                onChange={(e) => setReceiverDesc(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-primary"
                placeholder="Es. Una nuova fiamma...🔥"
                required
              />
            </div>
           
            
            <div>

              <div className="flex flex-row justify-center">
              <label htmlFor="zipFile" className="block text-gray-700 font-poppinsBold mb-1">
                Carica il file zip della conversazione
              </label>
              <button
                type="button"
                className="ml-2 text-blue-500 hover:text-blue-700"
                onClick={() => setShowInstructions(!showInstructions)}
              >
                <AiOutlineInfoCircle className="text-lg" />
              </button>
              </div>
              {showInstructions && (
                <div className="my-2 bg-blue-100  p-3 rounded-lg shadow-lg relative">
                  <button
                    onClick={() => setShowInstructions(false)}
                    className="absolute top-1 right-2 text-primary  text-lg font-bold"
                    aria-label="Close"
                  >
                    &times;
                  </button>
                  <p className="text-sm font-poppinsLight justify-start text-slate-700">
                    Segui le istruzioni per procurarti facilmente il file zip della tua conversazione. <br/><br/>
                    Su <b>iPhone</b>:<br/>
                    1) Accedoi al tuo Whatsapp dal cellulare (no web)<br/><br/>
                    2) Clicca su <b>Impostazioni > Chat > Esporta chat</b><br/><br/>
                    3) Seleziona la chat desiderata cliccando su "Allega file mulimediale"<br/><br/>
                    4) Attendi il download e salvala dove ti è più comodo (OneDrive, Drive...)<br/><br/>
                    5) Carica qui il file.<br/><br/>
                    Su <b>Android</b>:<br/>
                    1) Seleziona la chat desiderata<br/><br/>
                    2) Clicca sui tre puntivi verticali nell'angolo in alto a destra e seleziona: <b>Altro > Esporta chat</b><br/><br/>
                    3) Seleziona l'opzione per includere i file multimediali<br/><br/>
                    4) Attendi il download e salvala dove ti è più comodo (OneDrive, Drive...)<br/><br/>
                    5) Carica qui il file.<br/><br/>
                  </p>
                </div>
              )}

              <input
                type="file"
                id="zipFile"
                name="zipFile"
                onChange={handleFileChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none"
                accept=".zip"
                required
              />
              {zipFile && <p className="mt-2 text-sm text-gray-600">File selezionato: {zipFile.name}</p>}
            </div>
            <div className="flex flex-row justify-center items-center my-12">
                  <input
                      type="checkbox"
                      id="isPremium"
                      checked={isPremium}
                      onChange={(e) => {setIsPremium(e.target.checked)
                        
                      }}
                      className="mr-2 transform scale-150"
                  />
                  <label htmlFor="isPremium" className="text-base font-poppinsLight">
                      Imposta questo contenuto come a pagamento
                  </label>
              </div>
  
            <button
              type="submit"
              className="w-full bg-primary text-white font-semibold py-2 rounded-lg hover:bg-primary-dark transition duration-200"
            >
              Carica Conversazione
            </button>
          </form>
  
          {uploadMessage && (
            <div className="mt-4 text-center text-gray-700">
              {uploadMessage}
            </div>
          )}
        </div>
      </div>
      </>
  );
}

export default UploadConversation