import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi"; // Icona del cestino
import api from "../Api";
import Navbar from "../components/navbar";
import NavbarMobile from "../components/navbarmobile";
import { useUser } from "../contexts/UserContext";
import { loadStripe } from '@stripe/stripe-js';
import { SiTruenas } from "react-icons/si";

function SnippetList() {
  const { usernameParams, containerId, storiaId } = useParams(); // Prende i parametri dall'URL
  const navigate = useNavigate();
  const [snippets, setSnippets] = useState([]); // Stato per la lista degli snippet
  const [loading, setLoading] = useState(true); // Stato per indicare se sta caricando
  const [titoloStoria, setTitoloStoria] = useState("");
  const [showModal, setShowModal] = useState(false); // Stato per il modale di eliminazione snippet
  const [showDeleteStoryModal, setShowDeleteStoryModal] = useState(false); // Modale per eliminare la storia
  const [selectedSnippet, setSelectedSnippet] = useState(null); // Snippet selezionato
  const {user} = useUser() // Simulazione dell'utente autenticato
  
  

  const isVideo = (url) => {
    return url.toLowerCase().includes(".mp4") || url.toLowerCase().includes(".mov");
  };

  const [hasValidPurchase, setHasValidPurchase] = useState(false);
  const [showPaymentScreen, setShowPaymentScreen] = useState(false);
  const stripePromise = loadStripe('pk_test_51QNM3sP2eiNnuT9auX2IVHiDSwoQVPvIdnp4ZWWkPFtdqmLhypCmgSAgDAayWXNyMblzpd9FNMVNzwaV9asNBED4008Jnvb5mB');

  useEffect(() => {
    const fetchSnippets = async () => {
      try {
        const response = await api.get(
          `sparklingApi/contenitori/${containerId}/storie/${storiaId}/snippets-list/`
        );
        setSnippets(response.data.snippets);
        setTitoloStoria(response.data.titolo_storia); // Imposta il titolo
      } catch (error) {
        console.error("Errore nel recupero degli snippet:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSnippets();
  }, [containerId, storiaId]);

  const handleDeleteSnippet = async () => {
    try {
      await api.delete(
        `sparklingApi/contenitori/${containerId}/storie/${storiaId}/snippets/${selectedSnippet}/deletesnippet/`
      );
      setSnippets(snippets.filter((snippet) => snippet.id !== selectedSnippet));
      setShowModal(false);
    } catch (error) {
      console.error("Errore durante l'eliminazione dello snippet:", error);
    }
  };

  const handleDeleteStory = async () => {
    try {
      await api.delete(
        `sparklingApi/contenitori/${containerId}/storie/${storiaId}/deletestory/`
      );
      navigate(`/profile/${usernameParams}/`); // Reindirizza alla lista dei contenitori
    } catch (error) {
      console.error("Errore durante l'eliminazione della storia:", error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-gray-500">Caricamento in corso...</p>
      </div>
    );
  }


  const checkValidPurchase = async (snippet) => {
    try {
      // Se l'utente loggato è lo stesso creator, consenti l'accesso senza pagamento
      if (usernameParams === user.username) {
        setHasValidPurchase(true);
        setShowPaymentScreen(false);
        navigate(`/content/${containerId}/storia/${storiaId}/snippets/${snippet.id}`);
        
        return;
      }

      const response = await api.get(`/sparklingApi/checkpurchase/${usernameParams}/`);
      if (response.data.valid_purchase) {
        setHasValidPurchase(true);
        setShowPaymentScreen(false);
        navigate(`/content/${containerId}/storia/${storiaId}/snippets/${snippet.id}`);
      } else {
        setShowPaymentScreen(true);
      }
    } catch (error) {
      console.error('Errore durante il controllo della transazione valida:', error);
    } finally {
      setLoading(false);
    }
  };

  // Funzione per gestire il pagamento
  const handlePayment = async () => {
    try {
      const stripe = await stripePromise;
  
      // Richiedi al backend di creare una sessione di pagamento
      const response = await api.post('/sparklingApi/createcheckoutsession/', {
        creator: usernameParams,
        
      });
  
      const session = response.data;
  
      if (session.sessionId) {
        // Reindirizza l'utente alla schermata di pagamento di Stripe
        const result = await stripe.redirectToCheckout({ sessionId: session.sessionId });
  
        if (result.error) {
          console.error('Errore durante il reindirizzamento a Stripe:', result.error.message);
        }
      } else {
        console.error('Errore nella creazione della sessione di pagamento');
      }
    } catch (error) {
      console.error('Errore durante il pagamento:', error);
    }
  };

  if (showPaymentScreen === false) {
    return(
    <>
      <Navbar />
      <br />
      <div className="flex flex-col items-center min-h-screen">
        <p className="text-3xl font-poppinsBold text-primary mt-8">{titoloStoria}</p>
        <p className="flex justify-center text-gray-600 mb-6 mt-2">
          Lista completa degli snippet caricati per questa storia
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-11/12">
        {snippets.map((snippet, index) => (
            <div
                key={index}
                className="bg-white rounded-lg shadow-lg overflow-hidden cursor-pointer relative"
                onClick={() => {
                if (snippet.is_premium) {
                    checkValidPurchase(snippet)
                } else {
                    navigate(`/content/${containerId}/storia/${storiaId}/snippets/${snippet.id}`);
                }
                }}
            >
                <div className="relative">
                {/* Tag FREE o PREMIUM */}
                <span
                    className={`absolute top-2 left-2 px-3 py-1 text-xs font-poppinsLight rounded-full ${
                    snippet.is_premium ? "bg-gradient-to-r from-primary to-secondary text-white" : " bg-green-500 text-white"
                    }`}
                >
                    {snippet.is_premium ? "Premium" : "Free"}
                </span>

                {/* Verifica se il file è un video o un'immagine */}
                {snippet.preview_url && isVideo(snippet.preview_url) ? (
                    <ReactPlayer
                    url={snippet.preview_url}
                    playing={false}
                    controls
                    width="100%"
                    height="200px"
                    style={{ backgroundColor: "#000" }}
                    />
                ) : (
                    <img
                    src={snippet.preview_url}
                    alt={`Snippet ${index + 1}`}
                    className="w-full h-48 object-cover"
                    />
                )}
                </div>

                <div className="p-4">
                <p className="text-gray-800 font-semibold">Snippet #{index + 1}</p>
                <p className="text-gray-500 text-sm">Creato il: {snippet.created_at}</p>
                <div className="w-full flex justify-between mt-3 items-center">
                    <p className="font-poppinsLight text-primary">Scopri di più ></p>
                    {usernameParams === user.username && (
                    <FiTrash2
                        size={24}
                        className="w-8 h-8 p-1 bg-gray-200 text-gray-700 rounded-full flex items-center justify-center hover:bg-gray-300"
                        onClick={(e) => {
                        e.stopPropagation();
                        setSelectedSnippet(snippet.id);
                        setShowModal(true);
                        }}
                    />
                    )}
                </div>
                </div>
            </div>
            ))}

        </div>

        {/* Pulsante elimina storia */}
      {usernameParams === user.username && (
          <button
            onClick={() => setShowDeleteStoryModal(true)}
            className="mb-4 px-4 py-2 bg-red-500 text-white rounded-md mt-12"
          >
            Elimina Storia
          </button>
        )}
      </div>

      

      {/* Modale elimina snippet */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">Sei sicuro di voler eliminare questo snippet?</h2>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
              >
                Annulla
              </button>
              <button
                onClick={handleDeleteSnippet}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                Elimina
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modale elimina storia */}
      {showDeleteStoryModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">Sei sicuro di voler eliminare questa storia e tutti i suoi snippet?</h2>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowDeleteStoryModal(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
              >
                Annulla
              </button>
              <button
                onClick={handleDeleteStory}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                Elimina
              </button>
            </div>
          </div>
        </div>
      )}


      
      <br />
      <br />
      <br />
      <NavbarMobile />
    </>
    )
}

if (showPaymentScreen === true) {
    return(

        <>
            <div className="min-h-screen ">
            <Navbar />

            <div className="grid grid-cols-12 text-center mx-6 mt-24">
                <div className="md:col-span-3 sm:col-span-1 sm:block hidden"></div>
                <div className="md:col-span-6 sm:col-span-10 col-span-12 rounded-lg shadow-2xl p-6 ">
                <div className="flex justify-between items-center ">
                    <p className="font-poppinsBold text-primary text-sm">One-time payment</p>
                    <p className="rounded-full bg-slate-100 font-poppinsBold p-2 text-xs text-primary">
                    Accesso immediato
                    </p>
                </div>

                <div className="text-left my-6 text-slate-700 font-poppinsLight">
                    Accedi subito a tutti i contenuti che <b>{usernameParams}</b> ha condiviso con te.
                </div>

                <div className="text-left mt-8 flex items-baseline">
                    <p className=" text-black font-poppinsBold text-5xl">€6.99</p>
                    <p className="text-slate-700 font-poppinsLight text-sm pl-1">I.V.A. Compresa</p>
                </div>

                <button
                    onClick={handlePayment}
                    className="mt-8 w-full text-md bg-primary text-white font-poppinsBold  py-2 rounded-3xl hover:bg-transparent hover:border-2 hover:border-primary hover:text-primary hover:font-bold sm:w-4/5"
                >
                    Acquista ora >
                </button>

                <div className="mt-6">
                    <ul className="text-left ">
                    <li className="flex items-center mt-2">
                        <b className="text-primary mr-4 text-lg">✓</b>
                        <p className="font-poppinsLight text-slate-700 text-base">
                        Pagamento unico, nessun abbonamento mensile.
                        </p>
                    </li>

                    <li className="flex items-center mt-2">
                        <b className="text-primary mr-4 text-lg">✓</b>
                        <p className="font-poppinsLight text-slate-700 text-base">
                        Accesso di 24 ore a tutte le Chat e gli Snippet di {usernameParams}
                        </p>
                    </li>

                    <li className="flex items-center mt-2">
                        <b className="text-primary mr-4 text-lg">✓</b>
                        <p className="font-poppinsLight text-slate-700 text-base">
                        Fatti notare con i commenti e le reazioni ai messaggi ❤️😂😮😢😡
                        </p>
                    </li>
                    </ul>
                </div>
                </div>
                <div className="md:col-span-3 sm:col-span-1 sm:block hidden"></div>
            </div>

            <NavbarMobile />
            </div>
            </>



    )}


return null

}



export default SnippetList;
