import axios from "axios"
import { ACCESS_TOKEN } from "./Constants"

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL //serve per importare qualsiasi cosa in un env file. 
})
//baseURL: process.env.REACT_APP_API_URL //serve per importare qualsiasi cosa in un env file. 
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(ACCESS_TOKEN); //vedo nel local storage se ho il token
        if(token) {
            config.headers.Authorization = `Bearer ${token}` //cosi passo jtw auth token / aggiungo authorization header
        } 
        return config //se successful
    },
    (error) => {
        return Promise.reject(error) 
    }
)

export default api // esportiamo e da ora in poi usiamo questo al posto di axios per mandare le richieste così l'auth token lo mette in automatico.
// adesso un modo per predere token e proteggere routes su frontend e backend
