import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function StepComponent() {
  const { ref, inView } = useInView({
    triggerOnce: false, // L'animazione si può riattivare quando si esce e si rientra
    threshold: 0.3, // Il componente diventa visibile al 30%
  });

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.3, // I figli si animano uno dopo l'altro
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 }, // Iniziano nascosti con un'animazione dal basso
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }, // Appaiono con animazione
  };

  return (
    <div ref={ref} className="h-auto  flex flex-col justify-center items-center ">
      {/* Titolo */}
      <motion.p
        className="md:text-8xl text-6xl text-center font-poppinsBold text-slate-700 mb-12"
        initial={{ opacity: 0, y: -20 }}
        animate={inView ? { opacity: 1, y: 0, transition: { duration: 0.8 } } : { opacity: 0, y: -20 }}
      >
        Come funziona
      </motion.p>

      <motion.div
        className="flex flex-col md:flex-row gap-8 "
        variants={containerVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"} // L'animazione si attiva solo se inView è true
      >
        {/* Step 1 */}
        <motion.div
          variants={cardVariants}
          className="flex flex-col items-center text-center border border-slate-300 shadow-lg rounded-lg p-6 w-64 h-80 bg-gradient-to-br from-primary to-secondary"
          whileHover={{ scale: 1.05 }}
        >
          <div className="text-6xl font-poppinsBold text-white">1</div>
          <p className="text-2xl font-poppinsLight text-slate-700 mt-4 "><b>Iscriviti alla piattaforma</b></p>
          <p className="text-md text-white mt-2 px-4">
            Seleziona il tipo di profilo adatto a te (Fan o Creator) e completa la registrazione.
          </p>
        </motion.div>

        {/* Step 2 */}
        <motion.div
          variants={cardVariants}
          className="flex flex-col items-center text-center border border-slate-300 shadow-lg rounded-lg p-6 w-64 h-80 bg-gradient-to-br from-primary to-secondary"
          whileHover={{ scale: 1.05 }}
        >
          <div className="text-6xl font-poppinsBold text-white">2</div>
          <p className="text-2xl font-poppinsLight text-slate-700 mt-4"><b>Accedi ai contenuti</b></p>
          <p className="text-md text-white mt-2 px-4">
            Entra nelle Chat e negli Snippet che gli Influencer hanno deciso di condividere con te.
          </p>
        </motion.div>

        {/* Step 3 */}
        <motion.div
          variants={cardVariants}
          className="flex flex-col items-center text-center border border-slate-300 shadow-lg rounded-lg p-6 w-64 h-80 bg-gradient-to-br from-primary to-secondary"
          whileHover={{ scale: 1.05 }}
        >
          <div className="text-6xl font-poppinsBold text-white">3</div>
          <p className="text-2xl font-poppinsLight text-slate-700 mt-4"><b>Interagisci con i Creators</b></p>
          <p className="text-md text-white mt-2 px-4">
            Dì la tua, segui ogni risvolto della loro vita e tieni il passo con gli ultimi gossip e novità.
          </p>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default StepComponent;
