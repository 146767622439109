import React from 'react';
import logo from '../Img/1.png';
import { Link, useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

function Navbar() {
  const navigate = useNavigate();

  return (
    <div className="bg-white w-full fixed sm:relative top-0 left-0 z-50">
      <div className="flex items-center justify-between sm:grid sm:grid-cols-12 px-4 py-2">
        {/* Icona "Indietro" visibile solo su schermi piccoli */}
        <div className="sm:col-span-2 flex justify-start sm:justify-center">
          <button
            className="sm:hidden text-primary p-2 mr-4"
            onClick={() => navigate(-1)}
          >
            <FiArrowLeft size={24} />
          </button>
        </div>

        {/* Logo centrato */}
        <div className="sm:col-span-8 flex justify-center items-center">
          <img src={logo} className="md:max-h-[50px] max-h-[35px]" alt="Logo" />
        </div>

        {/* Pulsanti Home e Logout */}
        <div className="sm:col-span-2 flex justify-end items-center space-x-4">
          <Link to="/home">
            <button className="font-poppinsLight px-4 py-2 rounded-3xl bg-transparent text-primary font-bold sm:block hidden">
              Home
            </button>
          </Link>
          <Link to="/logout">
            <button className="bg-primary text-white font-poppinsLight px-4 py-2 rounded-3xl hover:bg-transparent hover:border-2 hover:border-primary hover:text-primary hover:font-bold sm:block hidden">
              Logout
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
