import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import ReactPlayer from "react-player";
import { FiPlus } from "react-icons/fi";
import api from "../Api";
import Spinner from "./spinner";
import { useParams } from "react-router-dom";

function StorieCards({ containerId }) {
    const [storie, setStorie] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentStoria, setCurrentStoria] = useState(null);
    const [photos, setPhotos] = useState([]);
    const [video, setVideo] = useState(null);
    const [uploading, setUploading] = useState(false);

    const photoInputRef = useRef(null);
    const videoInputRef = useRef(null);

    const { user } = useUser();
    const navigate = useNavigate();

    const usernameparams = useParams()

    const [isPremium, setIsPremium] = useState(false);
    

    useEffect(() => {
        const fetchStorie = async () => {
            try {
                setLoading(true);
                const response = await api.get(`sparklingApi/contenitori/${containerId}/storie/`);
                setStorie(response.data);
            } catch (error) {
                console.error("Errore nel recupero delle storie:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchStorie();
    }, [containerId]);

    const handleClick = (storia) => {
        
        // Reindirizza alla pagina con la lista degli snippet
        navigate(`../${usernameparams.username}/content/${containerId}/storie/${storia.id}/snippetslist`);
        
    };
    

    const handleAddSnippet = (storia) => {
        setCurrentStoria(storia);
        setModalOpen(true);
    };

    const handlePhotoUpload = (e) => {
        const files = Array.from(e.target.files);
        setPhotos(files);
    
        // Sincronizza l'input file con lo stato
        if (photoInputRef.current) {
            const dataTransfer = new DataTransfer();
            files.forEach((file) => dataTransfer.items.add(file));
            photoInputRef.current.files = dataTransfer.files;
        }
    };
    

    const handleVideoUpload = (e) => {
        setVideo(e.target.files[0]);
    };

    const handleRemovePhoto = (index) => {
        const updatedPhotos = photos.filter((_, i) => i !== index);
        setPhotos(updatedPhotos);
    
        // Sincronizza l'input file con lo stato aggiornato
        if (photoInputRef.current) {
            const dataTransfer = new DataTransfer();
            updatedPhotos.forEach((file) => dataTransfer.items.add(file));
            photoInputRef.current.files = dataTransfer.files;
        }
    };
    

    const handleRemoveVideo = () => {
        setVideo(null);
        if (videoInputRef.current) {
            videoInputRef.current.value = ""; // Resetta l'input file
        }
    };

    const handleSnippetSubmit = async (e) => {
        e.preventDefault();
        if (!photos.length && !video) {
            alert("Carica almeno un'immagine o un video.");
            return;
        }

        setUploading(true);

        try {
            const requestData = {
                immagini: photos.map((photo) => ({ name: photo.name, type: photo.type })),
                video: video ? { name: video.name, type: video.type } : null,
                is_premium: isPremium, // Aggiungi questo campo
            };

            const response = await api.post(
                `sparklingApi/contenitori/${containerId}/storie/${currentStoria.id}/snippets/`,
                requestData
            );

            const { presigned_urls, video_url } = response.data;

            for (const [index, photo] of photos.entries()) {
                const { url } = presigned_urls[index];
                await fetch(url, {
                    method: "PUT",
                    body: photo,
                    headers: { "Content-Type": photo.type },
                });
            }

            if (video && video_url) {
                await fetch(video_url, {
                    method: "PUT",
                    body: video,
                    headers: { "Content-Type": video.type },
                });
            }

            alert("Snippet aggiunto con successo!");
            setModalOpen(false);
            setPhotos([]);
            setVideo(null);
        } catch (error) {
            console.error("Errore durante l'aggiunta dello snippet:", error);
            alert("Errore durante l'aggiunta dello snippet.");
        } finally {
            setUploading(false);
        }
    };

    const handleCancel = () => {
        setModalOpen(false); // Chiude il modale
        setPhotos([]); // Resetta le immagini caricate
        setVideo(null); // Resetta il video caricato
    
        // Resetta gli input file
        if (photoInputRef.current) {
            photoInputRef.current.value = "";
        }
        if (videoInputRef.current) {
            videoInputRef.current.value = "";
        }
    };

    return (
        <div>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-1 mt-6">
                {storie.map((storia) => (
                    <div
                        key={storia.id}
                        className="relative bg-black aspect-square overflow-hidden rounded-lg cursor-pointer"
                        onClick={() => handleClick(storia)}
                    >
                        <div className="absolute inset-0">
                        {storia.preview ? (
                            storia.preview.toLowerCase().includes(".mp4") || storia.preview.toLowerCase().includes(".mov") ? (
                                <ReactPlayer
                                    url={storia.preview}
                                    playing
                                    muted
                                    loop
                                    width="100%"
                                    height="100%"
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)", // Centra il contenuto
                                        objectFit: "cover", // Copre il contenitore senza spazi
                                    }}
                                    config={{
                                        file: {
                                            attributes: {
                                                playsInline: true,
                                                preload: "auto",
                                                muted: true,
                                                style: { objectFit: "cover" },
                                            },
                                        },
                                    }}
                                />
                            ) : (
                                <img
                                    className="w-full h-full object-cover"
                                    src={storia.preview}
                                    alt={`Preview di ${storia.titolo}`}
                                />
                            )
                        ) : (
                            <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                                <p className="text-gray-500 text-sm">Nessuna anteprima disponibile</p>
                            </div>
                        )}

                        </div>
                        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-2">
                            <h3 className="text-white text-xs font-poppinsBold truncate">
                                {storia.titolo} <br />({storia.snippet_count}) snippet
                            </h3>
                        </div>
                        {user.username === storia.creator && (
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleAddSnippet(storia);
                                }}
                                className="absolute top-2 right-2 bg-white text-primary p-2 rounded-full shadow-md"
                            >
                                <FiPlus size={16} />
                            </button>
                        )}
                    </div>
                ))}
            </div>

            {modalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white w-11/12 md:w-3/4 lg:w-1/2 p-6 rounded-lg shadow-lg max-h-[80vh] overflow-y-auto">
                        <h2 className="text-2xl font-poppinsLight text-center text-primary mb-4">
                            Aggiungi uno snippet a "{currentStoria.titolo}"
                        </h2>
                        <form onSubmit={handleSnippetSubmit}>
                            <div className="mb-4 flex flex-col items-center">
                                <label className="block text-gray-700 mb-2 font-poppinsLight text-lg">Carica gli screenshot</label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handlePhotoUpload}
                                    ref={photoInputRef}
                                    className="block md:w-3/4 w-full border border-gray-300 rounded-lg"
                                />
                                <div className="flex mt-2 space-x-2">
                                    {photos.map((photo, index) => (
                                        <div key={index} className="relative w-16 h-16">
                                            <img
                                                src={URL.createObjectURL(photo)}
                                                alt="preview"
                                                className="object-cover w-full h-full rounded-md"
                                            />
                                            <button
                                                type="button"
                                                onClick={() => handleRemovePhoto(index)}
                                                className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs"
                                            >
                                                ×
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mb-4 flex flex-col items-center">
                                <label className="block text-gray-700 mb-2 font-poppinsLight text-lg">Carica un video</label>
                                <input
                                    type="file"
                                    accept="video/*"
                                    onChange={handleVideoUpload}
                                    ref={videoInputRef}
                                    className="block md:w-3/4 w-full border border-gray-300 rounded-lg"
                                />
                                {video && (
                                    <div className="mt-2">
                                        <video src={URL.createObjectURL(video)} className="w-full rounded-md" controls />
                                        <button
                                            type="button"
                                            onClick={handleRemoveVideo}
                                            className="mt-2 bg-red-500 text-white px-4 py-2 rounded-lg"
                                        >
                                            Rimuovi video
                                        </button>
                                    </div>
                                )}
                            </div>
                                {uploading && (
                                    <div className="flex flex-col justify-center items-center mb-4">
                                        <p className="text-primary font-poppinsLight mb-2">Potrebbe volerci qualche minuto...</p>
                                        <Spinner />
                                    </div>
                                )}
                                <div className="flex flex-row justify-center items-center my-8 ">
                                    <input
                                        type="checkbox"
                                        id="isPremium"
                                        checked={isPremium}
                                        onChange={(e) => setIsPremium(e.target.checked)}
                                        className="mr-2 transform scale-150"
                                    />
                                    <label htmlFor="isPremium" className="text-base font-poppinsLight">
                                        Imposta questo contenuto come a pagamento
                                    </label>
                                </div>
                                <div className="flex justify-end space-x-4">
                                    <button
                                        type="submit"
                                        className={`bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-dark font-poppinsLight text-lg ${
                                            uploading ? "opacity-50 cursor-not-allowed" : ""
                                        }`}
                                        disabled={uploading}
                                    >
                                        {uploading ? "Caricamento..." : "Salva Snippet"}
                                    </button>
                                    <button
                                        type="button"
                                        className={`bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300 font-poppinsLight text-lg ${
                                            uploading ? "opacity-50 cursor-not-allowed" : ""
                                        }`}
                                        onClick={handleCancel}
                                        disabled={uploading}
                                    >
                                        Annulla
                                    </button>
                                </div>

                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default StorieCards;
