import React from "react";
import { motion, useCycle } from "framer-motion";

const Spinner = () => {
    const [color, cycleColor] = useCycle("#4A5BFF", "#00FFFF", "#EAAF3E");

    // Cambia colore ogni 0.5 secondi
    React.useEffect(() => {
        const interval = setInterval(() => {
            cycleColor();
        }, 2000);

        return () => clearInterval(interval); // Cleanup
    }, [cycleColor]);

    return (
        <motion.div
            animate={{ rotate: 360 }}
            transition={{
                repeat: Infinity,
                duration: 1,
                ease: "linear",
            }}
            style={{
                width: "2rem",
                height: "2rem",
                border: "4px solid #d1d5db", // Light gray border
                borderTop: `4px solid ${color}`, // Dynamic color
                borderRadius: "50%",
                marginLeft: "0.5rem",
            }}
        ></motion.div>
    );
};

export default Spinner;
