import React, { useState } from "react";

// Mappa di reazioni con le emoticon corrispondenti
const reactionMap = {
  cuore: "❤️",
  risata: "😂",
  stupore: "😮",
  triste: "😢",
  rabbia: "😡",
};

const SnippetComment = ({ comments, reactions, user, handleAddComment, handleDeleteComment }) => {
  const [newComment, setNewComment] = useState("");

  return (
    <div className="h-full flex flex-col">
      {/* Overview delle reazioni */}
      <div className="flex items-center justify-start mb-4 space-x-4">
        {Object.keys(reactions).length > 0 ? (
          Object.entries(reactions).map(([reaction, count]) => (
            <div
              key={reaction}
              className="flex items-center space-x-2 text-sm text-slate-700"
            >
              <span>{reactionMap[reaction] || reaction}</span>
              <span className="font-poppinsBold">{count}</span>
            </div>
          ))
        ) : (
          <p className="text-slate-500 text-sm">Nessuna reazione presente</p>
        )}
      </div>

      {/* Contenitore principale */}
      <div className="flex flex-col flex-1 overflow-hidden">
        {/* Sezione Commenti con scrollbar */}
        <div className="flex-1 overflow-y-auto pr-2">
          <h3 className="text-sm font-poppinsBold">Commenti</h3>
          <ul className="text-slate-700 mt-2">
            {comments.length > 0 ? (
              comments.map((comment) => (
                <li
                  key={comment.id}
                  className="border-b border-slate-300 py-2 flex items-center justify-between"
                >
                  <div className="flex items-center w-full justify-between">
                    <div
                      className={`break-words ${
                        comment.username === user.username ? "max-w-[80%]" : "w-full"
                      }`}
                    >
                      <p className="font-poppinsLight md:text-base text-sm">
                        <strong className="font-poppinsBold">{comment.username}</strong>: {comment.testo}
                      </p>
                      <p className="text-xs text-slate-500">
                        {new Date(comment.data_creazione).toLocaleString()}
                      </p>
                    </div>
                    {comment.username === user.username && (
                      <button
                        onClick={() => handleDeleteComment(comment.id)}
                        className="ml-2 text-red-500 text-xs flex-shrink-0"
                      >
                        Elimina
                      </button>
                    )}
                  </div>
                </li>
              ))
            ) : (
              <li className="text-slate-500">Nessun commento presente</li>
            )}
          </ul>
        </div>

        {/* Input per nuovo commento, sempre visibile */}
        <div className="flex-shrink-0 border-t pt-2 bg-white">
          <div className="flex items-center space-x-2">
            <textarea
              value={newComment}
              onChange={(e) => {
                setNewComment(e.target.value);
                e.target.style.height = "auto"; // Resetta l'altezza per calcolarla di nuovo
                e.target.style.height = `${e.target.scrollHeight}px`; // Imposta l'altezza in base al contenuto
              }}
              placeholder="Scrivi un commento..."
              className="border border-slate-300 rounded-md p-2 text-xs w-full resize-none overflow-hidden"
              rows="1"
            />
            <button
              onClick={() => {
                handleAddComment(newComment);
                setNewComment(""); // Resetta il campo di input
              }}
              className="px-4 py-1 bg-primary h-8 text-white rounded-md text-xs"
            >
              Invia
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnippetComment;
