// wrapper per una rout prottetta, se fatto dobbiamo avere un auth token per accedere alla route.  

import { Navigate } from "react-router-dom";
import  {jwtDecode}  from "jwt-decode";
import api from "../Api"
import { REFRESH_TOKEN, ACCESS_TOKEN } from "../Constants";
import { useState, useEffect } from "react";
import { useUser } from '../contexts/UserContext';//


//children è quello che wrappo. Qui check se authorized prima che acceda alla route, altrimenti le reindirizzo al login
function ProtectedRoute({children}) {

    const [isAuthorized, setIsAuthorized] = useState(null)
    //const [user, setUser] = useState(null); // Stato per memorizzare i dati dell'utente/////////////////////////////
    const { setUser } = useUser()
    const { user } = useUser()

    useEffect(() => {
        auth().catch(() => setIsAuthorized(false))
    }, []) // appena carico la protected route chiamiamo la auth function

    // Richiesta presa dati user dal backend ogni volta. 
    const fetchUserProfile = async () => {///////////////////////////////////////////
        try {
            const res = await api.get("/sparklingApi/user/getprofile/");
            if (res.status === 200){
                setUser(res.data);
            }
            
        } catch (error) {
            console.log("Errore nel recupero dei dati utente:", error);
        }
    };
    // Fino a qui

    //questo refresha l'auth token automaticamente
    const refreshToken = async () => {
        const refreshtoken = localStorage.getItem(REFRESH_TOKEN)

        try { //request al backed con il refresh token per prendere un altro access token.
            const res = await api.post("/sparklingApi/token/refresh/", {refresh: refreshtoken});

            if (res.status === 200) {
                localStorage.setItem(ACCESS_TOKEN, res.data.access) //lo mettiamo nel localstorage uguale a res.data.access
                setIsAuthorized(true)
                await fetchUserProfile(); // Recupera i dati utente aggiornati////////////////////////////////
            } else {
                setIsAuthorized(false)
            }

        } catch (error) {
            console.log(error)
            setIsAuthorized(false);
        }
    }

    //questo fa check se dobbiamo rifreshare il token o no. 
    const auth = async () => {
        const token = localStorage.getItem(ACCESS_TOKEN)
        if (!token) {
            setIsAuthorized(false)
            return
        }

        const decoded = jwtDecode(token)
        const tokenExpiration = decoded.exp //exp date
        const now = Date.now() / 1000 //in secondi così

        if (tokenExpiration < now) {
            await refreshToken()
        } else {
            setIsAuthorized(true)
            await fetchUserProfile(); // Recupera i dati utente///////////////////////////////////////////////
        }

    }

if (isAuthorized === null || (isAuthorized && !user)) {/////////////////////////////////
    return <div>Loading...</div> //Finchè non ho uno state diverso da null 

}

// Controllo per creator non approvati
//if (isAuthorized && user?.profile?.type === "Creator" && !user?.profile?.is_approved) {
if (isAuthorized && !user?.profile?.is_approved) {
    return <Navigate to="/notapproved" />;
} else {

return isAuthorized ? children : <Navigate to = "/login"/> //se autorizzato restituisco il children altrimenti la apgina di login
}

}

export default ProtectedRoute