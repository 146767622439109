import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../Api";
import { useSwipeable } from "react-swipeable";
import { AiOutlineArrowLeft, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

function StoryContent() {
    const { contenitoreId, storiaId } = useParams();
    const navigate = useNavigate();
    const [snippets, setSnippets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [vh, setVh] = useState(window.innerHeight); // Altezza dinamica del viewport
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768); // Controlla se lo schermo è grande

    useEffect(() => {
        const updateVhAndScreenSize = () => {
            setVh(window.innerHeight);
            setIsLargeScreen(window.innerWidth > 768); // Aggiorna se lo schermo è grande
        };

        window.addEventListener("resize", updateVhAndScreenSize);
        updateVhAndScreenSize();

        return () => {
            window.removeEventListener("resize", updateVhAndScreenSize);
        };
    }, []);

    useEffect(() => {
        const fetchSnippets = async () => {
            try {
                const response = await api.get(
                    `sparklingApi/content/${contenitoreId}/storie/${storiaId}/snippets/`
                );
                const combinedSnippets = response.data.flatMap((snippet) => {
                    const media = [];
                    if (snippet.video) {
                        media.push({ type: "video", url: snippet.video });
                    }
                    if (snippet.immagini) {
                        snippet.immagini.forEach((image) => {
                            media.push({ type: "image", url: image });
                        });
                    }
                    return media;
                });
                setSnippets(combinedSnippets);
            } catch (error) {
                console.error("Errore nel recupero degli snippet:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSnippets();
    }, [contenitoreId, storiaId]);

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex < snippets.length - 1 ? prevIndex + 1 : prevIndex
        );
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrevious,
    });

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <p className="text-gray-500">Caricamento in corso...</p>
            </div>
        );
    }

    return (
        <div
            className="flex flex-col items-center bg-black relative overflow-hidden"
            {...swipeHandlers}
            style={{ height: vh }} // Imposta l'altezza dinamica per evitare scroll
        >
            {/* Bottone per tornare indietro */}
            <button
                onClick={() => navigate(-1)}
                className="absolute top-4 left-4 bg-gray-800 text-white rounded-full p-2 focus:outline-none z-50"
            >
                <AiOutlineArrowLeft size={24} />
            </button>

            {snippets.length > 0 && (
                <div className="relative w-full h-full">
                    {snippets[currentIndex].type === "video" ? (
                        <video
                            className="w-full h-full object-contain"
                            src={snippets[currentIndex].url}
                            autoPlay
                            loop
                            muted
                        />
                    ) : (
                        <img
                            className="w-full h-full object-contain"
                            src={snippets[currentIndex].url}
                            alt={`Snippet ${currentIndex + 1}`}
                        />
                    )}

                    {/* Frecce di navigazione per schermi grandi */}
                    {isLargeScreen && (
                        <>
                            <button
                                onClick={handlePrevious}
                                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full shadow-md focus:outline-none z-50"
                                style={{ display: currentIndex > 0 ? "block" : "none" }}
                            >
                                <AiOutlineLeft size={24} />
                            </button>
                            <button
                                onClick={handleNext}
                                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full shadow-md focus:outline-none z-50"
                                style={{
                                    display: currentIndex < snippets.length - 1 ? "block" : "none",
                                }}
                            >
                                <AiOutlineRight size={24} />
                            </button>
                        </>
                    )}

                    {/* Indicatore con pallini e sezione reazioni/commenti */}
                    <div className="absolute bottom-4 w-full flex flex-col items-center space-y-2 pointer-events-none">
                        <div className="flex justify-center items-center space-x-2">
                            {snippets.map((_, index) => (
                                <div
                                    key={index}
                                    className={`w-2 h-2 rounded-full ${
                                        index === currentIndex ? "bg-white" : "bg-gray-400"
                                    }`}
                                ></div>
                            ))}
                        </div>
                        <div
                            className="bg-black bg-opacity-50 text-white w-11/12 md:w-1/2 p-2 rounded-lg flex justify-around items-center cursor-pointer pointer-events-auto"
                            onClick={() => setShowModal(true)}
                        >
                            <span>❤️😂😮😢😡: <b>123</b></span>
                            <span>Commenti: <b>45</b></span>
                        </div>
                    </div>
                </div>
            )}

            {/* Modale per commenti e reazioni */}
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
                    <div className="bg-white w-11/12 md:w-1/2 p-6 rounded-lg shadow-lg relative">
                        <h2 className="text-xl font-bold mb-4">Commenti e Reazioni</h2>
                        <button
                            onClick={() => setShowModal(false)}
                            className="absolute top-4 right-4 bg-gray-200 text-gray-800 rounded-full p-2 focus:outline-none"
                        >
                            ✕
                        </button>

                        <div className="space-y-4 max-h-[60vh] overflow-y-auto">
                            <p className="text-gray-500">Nessun commento o reazione disponibile al momento.</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default StoryContent;
